<template>
    <div class="page-rooter-fff">
        <nav-bar header-title="超鹿燃脂小班课·公开课"  backColor="#ffffff" :show-back="true" :header-back="newAppBack" />
        <app-page id="box" class="page-img">
            <img  @click="jump(item.jumpUrl)" v-for="(item, index) in list" :key="index" :src="item.img" alt="">
        </app-page>
    </div>
</template>

<script>
import {newAppBack, initBack, gotoCampExclusiveDetail, defaultApp, gotoAppPage} from '@/lib/appMethod';
import navBar from "@/components/nav-bar/nav-bar";
import appPage from "@/common/components/appPage";
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';

export default {
    components: {
        navBar,
        appPage,
    },
    mixins: [userMixin],
    data() {
        return {
            list: [
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/1.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/2.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/3.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/4.jpg'},
                {jumpUrl: '1', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/5.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/6.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/11.png'},
                // {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/7.jpg'},
                // {jumpUrl: '2', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/8.jpg'},
                {jumpUrl: '', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/9.jpg'},
                {jumpUrl: '3', img: 'https://img.chaolu.com.cn/ACT/micro-popularize-2023/10.jpg'},
            ]
        };
    },
    methods: {
        newAppBack,
        jump(jumpUrl) {
            switch (jumpUrl) {
                case '1':
                    try {
                        let id
                        if(this.cityId === '036652995ac5451fbb6791c3491e1c2d') {
                            id = '127'
                        }else if(this.cityId === '3') {
                            id = '126'
                        }else if(this.cityId === '818447834079563776'){
                            id = '128'
                        }
                        gotoAppPage(12, id)
                    }catch (e) {
                        console.log(e)
                    }
                    break
                case '2':
                    this.goToInvite()
                    break
                case '3':
                    this.goToBuy()
                    break
            }
        },
        goToBuy(v = '') {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/course-buy&userId=1&token=1&packageType=${v}`,
                })
            } else {
                this.$router.push(`/course-buy?userId=1&token=1&packageType=${v}`)
            }
        },
        goToInvite() {
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.navigateTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/invite-2023&userId=1&token=1&location=1&cityId=1`,
                })
            } else {
                this.$router.push(`/superdeer-activity/invite-2023?userId=1&token=1`)
            }
        },
    },
    async created() {
        initBack();
        await this.$getUserId();
        await this.$getCityId();
        // this.countPoint('49', '315', 1567)
    },
};
</script>

<style scoped lang="less">
.page-rooter-fff{
    background-color: #F9FAFB;
    .page-img{
        img{
            width: 100%;
            display: block;
        }
    }
}
</style>
